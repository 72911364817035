import styles from "./index.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project_Gallery = ({ gallery }: { gallery?: any }) => {
  return (
    gallery && (
      <div className={styles.gallery}>
        <div className={styles.info_header}>
          <h6>Gallery</h6>
        </div>
        <div className={styles.gallery_items}>
          {gallery?.map((r: any, i: number) => {
            return (
              <div className={styles.item} key={i}>
                <LazyLoadImage
                  src={
                    process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS +
                    r?.directus_files_id.id
                  }
                  alt="thumb"
                  effect="blur"
                  wrapperClassName={styles.image}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.divider} />
      </div>
    )
  );
};

export default Project_Gallery;
