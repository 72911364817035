import { useState } from "react"
import { useWallet } from "../context/connectWallet"
import chains from "../Chains.json"
import { SMALLEST,REACT_APP_CHAIN_PREFIX } from "../constants"

const useBalance = () => {
  const {address, client} = useWallet()
  const [balance, setBalance] = useState(0)
  const token = chains?.[REACT_APP_CHAIN_PREFIX]?.token ?? 'juno'
  const name = chains?.[REACT_APP_CHAIN_PREFIX]?.name ?? 'juno'
  const fetchBalance = async () => {
    try{
      if(client && address) {
        const bal = await client.getBalance(address, token)
        setBalance(bal.amount / SMALLEST)
      }
    }catch (e){
      setBalance(0)
    }
  }

  return {
    balance,
    token,
    name,
    fetchBalance
  }
}
export default useBalance